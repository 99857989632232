import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import resistanceband from "images/resistance-band.svg";
import callinghelp from "images/calling-help.svg";
import crasherror from "images/crashed-error.svg";


import AnchorLink from 'react-anchor-link-smooth-scroll'

import Header, { NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks, PrimaryLink } from "../headers/light.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-10 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-xl`;

const Content = tw.div`mt-4`;

const Card = styled.div(props => [
  tw`mt-4 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
////const Image = styled.div(props => [
////  `background-image: url("${props.imageSrc}");`,
////  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
////]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded flex-shrink-0 bg-cover bg-center mx-2 sm:mx-4 md:mx-2 lg:mx-4`, // Keep the margins for spacing
  `background-size: contain;`, // Keeps the image aspect ratio
  `width: 100%;`, // Make the width responsive
  `max-width: 426.658px;`, // Set a max-width to match your design specifications
  `height: auto;`, // Adjust the height automatically based on the aspect ratio
  `aspect-ratio: 426.658 / 575.999;`, // Maintain aspect ratio (use your image's aspect ratio)
  `background-repeat: no-repeat;`,
  // Add a media query for devices with a width less than a specific breakpoint
  `@media (max-width: 768px) {`,
  `  width: 80vw;`, // Adjust the width on smaller screens to ensure it fits
  `  height: auto;`, // Adjust the height automatically
  `  background-position: center;`, // Ensure the background image is centered
  `}`,
]);

const Details = tw.div`mt-2 md:mt-0 md:max-w-md mx-4 sm:mx-2 md:mx-4 lg:mx-4`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
//const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;
const NavLink = tw.div`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium text-primary-500 font-bold`;
  const cards = [
    {
      imageSrc:
        resistanceband,
      title: "Not enough time in the day to get through everything?",
      description:
        "The clock is ticking, and with each passing minute, you're not only losing time but also money and opportunities. Those extra hours spent on manual data entry, fixing scheduling mishaps, and following up on payments – it's a hefty price to pay. And let's be honest, this constant interruption is exhausting. ",
      url: "#ContactUs"
    },

    {
      imageSrc:
        callinghelp,
      title: "Struggling to move forward with your business?",
      description:
        "You've tried different methods, but nothing seems to streamline things the way you need. Juggling multiple tools, dealing with no-shows, and manually sending out invoices – it's far from the efficient process you envisioned. Wasn't running your business supposed to be simpler, more enjoyable?",
      url: "#ContactUs"
    },

    {
      imageSrc:
        crasherror,
      title: "Ready for a Change?",
      description:
        "It's time to take action. Imagine a world where invoicing is automated, appointments manage themselves, and your client interactions are smooth and stress-free. That's not just a dream – it's what we offer.",
      url: "#ContactUs"
    }
  ];

  return (
    <Container id="WhyAutomation">
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Drowning in Admin Work?</HeadingTitle>
          <HeadingDescription>
          Every day, it's the same story. You're buried under a mountain of paperwork, chasing invoices, juggling client appointments, and tackling last-minute cancellations. It's stressful, right? You started your business to pursue your passion and help others, not to get bogged down in endless admin.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <NavLink><AnchorLink href="#ContactUs">Simplify Your Business – Start Free</AnchorLink></NavLink>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
