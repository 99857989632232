import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import HowItWorks from "components/faqs/SingleCol.js";
import Whatwedo from "components/features/TwoColWithButton";
import OurSolutions from "components/features/ThreeColWithSideImage.js"
import CTA from "components/cta/GetStartedLight.js"
import Pain from "components/features/VerticalWithAlternateImageAndText.js"
//import UseCases from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import heroimage from "images/HeroSynthetixMedia.png";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  //const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage disabled>
      <Hero />
      <Pain />
      <CTA />
      <OurSolutions />
      <HowItWorks />
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
}
