import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-700 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "",
  faqs = [
    {
      question: "What is Digital Review Management?",
      answer:
        "Digital Review Management is the process by which a company can build postive feedback, respond to negative sentiments about their brand in online conversations and minimise the effect of negative publicity by suppressing damaging web pages in Google and social media."
    },
    {
      question: "Why do I need Digital Review Management?",
      answer:
        "You can’t opt out of review management. For many small businesses, the complete lack of proactive reputation management leaves them in the position of a sitting duck. With little more than just a website to represent them online, they're in a precarious position if anyone creates a site to target them or even just posts something on social media, as those items could rank for their business name searches. For established local businesses, people may frequently be searching for them by business name — so, having bad stuff rank in branded search results can directly impact their bottom line."
    },
    {
      question: "What can I do about negative reviews?",
      answer:
        "Business owners recognise the impact of negative reviews can have on their business. You cannot remove negative reviews, but there are strategies to deal with this. Customers are looking to spend their hard earned money and the last thing they want to do is to be scammed. If your business had all 5 star reviews, with no neutral or negative scores, it can actually work against you because it looks so suspicious! Consumers know that your business cannot be perfect, and your online reviews should reflect this. Neutral reviews are often the most honest, whilst negative reviews provide you with the ability to engage and fix the mistake."
    },
    {
      question: "Why should I care and respond to reviews? ",
      answer:
        "There are 3 main reasons why you should respond to reviews, good or bad. Firstly, it shows your customers that you care enough to respond and listen to their feedback. Secondly, It shows potential customers that you're on top of your reviews, which gives them that extra confidence to purchase from you. Finally, a lot of review sites, including Google, will actually notify the customer when you answer their review, which reminds them of you and can serve as a great catalyst for repurchases and increasing retention."
    },
    {
      question: "I haven't used this in the past - why start now?",
      answer:
        "Technology moves fast, and your business needs to move with it. Because if it doesn't, your at risk. Your competitors surely are, and they are already using this platform and reaping all of the benefits that come with it. In the eyes of your prospective customers very little does it come down to your website anymore. More importantly, it's your Google listing against theirs and who has more reviews. Your competitors simply have more reviews than you do, and therefore you are already losing customers because of this. 10 years ago, business was done very differently. Now it's all about those reviews."
    },
    {
      question: "Do you allow suppressing negative reviews?",
      answer:
        "Absolutely not. You will notice that our process always gives the customer the option to leave a third party review, whether they are happy or not. There are a few reasons for this. Firstly, It's not hard for your customers to notice when you're taking their happy reviews publicly and negative reviews internally. Using these methods will cause your customers to trust you less, and your unhappy customers will inevitably find a way to get their voices heard anyway. Secondly, it breaks the terms and conditions of most review sites out there, so can lead to problems for your review profile in the future. All it takes is one of your customers reporting your practices to the review sites, and you risk your profile being banned. Finally, unhappy feedback is only natural for any business, and it makes you look real. In this sense, potential customers will be looking at how you respond to that feedback than anything."
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer id="FAQs">
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
